import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import VisitorLayout from 'src/components/layout/VisitorLayout';
import Heading from 'src/components/typography/Heading';
import Paragraph from 'src/components/typography/Paragraph';
import { OutlinedButton } from 'src/components/buttons/Primary';
import heroRegisterTeacher from 'src/images/register/hero-register-teacher.jpg';
import { navigate } from 'gatsby';
import useLocalizedRoutes from '../../hooks/useLocalizedRoutes';

export default function ConfirmEmailTeacher(props) {
    const {location} = props;
    const {registerTeacher} = useLocalizedRoutes();
    const [email, setEmail] = useState('');
    const {t} = useTranslation();

    useEffect(() => {
        if (location.state) {
            setEmail(location.state.values.email);
        }
    }, [location]);

    return (
        <VisitorLayout {...props} hero={heroRegisterTeacher} title={t('teacher.confirmEmail.title')}>
            <div className="px-6 lg:px-0 pt-12 lg:pt-24">
                <Heading type='head-title-wrap'>{t('teacher.confirmEmail.title')}</Heading>
                <div className="py-10">
                    <Paragraph>{t('teacher.confirmEmail.body', {email: email})}</Paragraph>
                </div>
                {/*<OutlinedButton>*/}
                {/*    {t('teacher.confirmEmail.button')}*/}
                {/*</OutlinedButton>*/}

                {/* disabled as there is an unautherized error from the api and the verify email is not send */}

                {/*<OutlinedButton*/}
                {/*    onClick={() => navigate(registerTeacher)}>*/}
                {/*    {t('teacher.confirmEmail.back')}*/}
                {/*</OutlinedButton>*/}
            </div>
        </VisitorLayout>
    );
}
